




























































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import * as SchoolApi from "@/api/school";
import * as DashBoardApi from "@/api/examDashboard";
import { PageNum, School, ExamRoom } from "@/tool/interface-index";
import { ExamSchool } from "@/tool/_class";
import * as _ from "lodash";
import { getSchoolCascadeList } from "@/api/school";

class SchoolListItem extends ExamSchool {
  hasChildren: boolean = true;
  children: ExamRoom[] = [];
}
@Component({
  name: "StatisticsByExamSchool",
  components: {
    cardBox,
    pagination
  }
})
export default class extends Vue {
  private query: {
    schoolName?: string,
    areaId?: string,
    schoolId?: string,
    keyWords?: string,
    cascadeExamSchoolId: string[][],
    monthId: number,
  } = {
      schoolName: "",
      cascadeExamSchoolId: [],
      monthId: 0
    };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10
  };
  private schoolOptions: any[] = [];
  private days: { dayId: number, dayName: string }[] = [];
  private months: { monthId: number, monthName: string }[] = [];
  private examSchools: Array<SchoolListItem> = [];
  private examTimeTotals: Array<DashBoardApi.ExamTimeInfoTotalByExamSchoolAndExamStateTime> = [];


  private getCascadeItem(keyId: string, cascades: any[]): any {
        let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schoolOptions);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]['children'];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      }//switch
    }

  }

  private loading: boolean = false;
  private async getSchoolData() {
    try {
      this.loading = true;
      //
      const resp0 = await SchoolApi.getSchoolList(_.merge({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage
      }, {
        schoolIds: this.query.cascadeExamSchoolId.map((value: string[]) => {
          return _.last(value) || ""
        }),
        schoolName: this.query.schoolName
      }))

      this.examSchools.splice(0, this.examSchools.length);
      resp0.data.items.forEach((element: any) => {
        let item: SchoolListItem = new SchoolListItem();
        _.merge(item, element);
        this.examSchools.push(item);
        console.log(`school item: ${JSON.stringify(item)}`);
      });
      this.examSchools = resp0.data.items;
      this.pageNum.totalPage = resp0.data.totalPage;
      //DashBoardApi
      const resp1 = await DashBoardApi.getExamTimeInfoTotalByExamSchoolAndExamStateTime({
        pageSize: 9999,
        curPage: 1,
        schoolIds: this.examSchools.map((value: SchoolListItem) => {
          return value.schoolId
        }),
        examStateMonthId: this.query.monthId.toString(),
        keyWords: this.query.schoolName,
        examStateTimeBetween: '',
        examStateTimeAnd: ''
      })
      this.examTimeTotals.splice(0, this.examTimeTotals.length);
      resp1.data.items.forEach((element: any) => {
        this.examTimeTotals.push(element);
      });
    } finally {
      this.loading = false;
    }
  }

  examDateColFormatter(row: SchoolListItem, column: any) {
    const __total = this.examTimeTotals.find((item: DashBoardApi.ExamTimeInfoTotalByExamSchoolAndExamStateTime) => {
      const columnKey = `examDate_${item.examStateDay}`;
      //console.log(`examDateColFormatter: ${column.columnKey}, ${columnKey}, ${item.examSchoolId}, ${row.schoolId}`);
      return item.examSchoolId == row.schoolId && columnKey == column.columnKey;
    });
    if (__total) {
      return __total.examTimeCount.toString();
    }
    return ""
  }

  examTimeCountColFormatter(row: SchoolListItem, column: any) {
    const __examTimeCounts: number[] = this.examTimeTotals.map((value: DashBoardApi.ExamTimeInfoTotalByExamSchoolAndExamStateTime) => {
      if (value.examSchoolId == row.schoolId) {
        return value.examTimeCount
      }
      return 0
    });
    let examTimeCount: number = 0;
    for (let i = 0; i < __examTimeCounts.length; i++) {
      examTimeCount = examTimeCount + __examTimeCounts[i]
    }
    return examTimeCount;
  }

  examStudentCountColFormatter(row: SchoolListItem, column: any) {
    const __examStudentCounts: number[] = this.examTimeTotals.map((value: DashBoardApi.ExamTimeInfoTotalByExamSchoolAndExamStateTime) => {
      if (value.examSchoolId == row.schoolId) {
        return value.examStudentCount
      }
      return 0
    });
    let examStudentCount: number = 0;
    for (let i = 0; i < __examStudentCounts.length; i++) {
      examStudentCount = examStudentCount + __examStudentCounts[i]
    }
    return examStudentCount;
  }

  querySchoolClick() {
    this.pageNum.curPage = 1;
    this.getSchoolData();
  }


  private upDataPage() {
    this.getSchoolData();
  }

  activated() {
    this.getSchoolData();
  }
  mounted() {
    this.days.splice(0, 31);
    for (let i = 1; i <= 31; i++) {
      this.days.push({ dayId: i, dayName: i.toString() });
    }
    this.months.splice(0, this.months.length);
    const d = new Date();
    console.log(`${d.toDateString()}`)
    this.months.push({
      monthId: d.getFullYear() * 100 + d.getMonth() + 1,
      monthName: `${d.getFullYear()}年${d.getMonth() + 1}月`
    });
    this.query.monthId = this.months[0].monthId;
    getSchoolCascadeList().then(({ data }) => {
      this.schoolOptions = data.items;
      this.removeNullChildren(this.schoolOptions);
    });
  }
  private loadExamRoom(tree: any, treeNode: any, resolve: any) {
    resolve([])
  }
}
